import axios from '@axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
axios.defaults.headers.post['Content-Type'] = 'multipart/form-data'
axios.defaults.baseURL = 'https://plankton-app-9npsd.ondigitalocean.app/api/'
// axios.defaults.baseURL = "http://192.168.0.200:3000/api/";
// axios.defaults.baseURL = 'http://localhost:3000/api/'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // fetchEmails(ctx, payload) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get('/apps/email/emails', { params: payload })
    //       .then(response => resolve(response))
    //       .catch(error => reject(error))
    //   })
    // },
    fetchEmails(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('contacts/search', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmail(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .get(`contacts/${payload.emailIds}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailType(ctx, payload) {
      console.log(payload)
      return new Promise((resolve, reject) => {
        axios
          .put('contacts', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEmailLabels(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/email/update-emails-label', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    paginateEmail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/email/paginate-email', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
