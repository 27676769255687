<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div class="email-app-menu">
          <div class="form-group-compose text-center compose-btn">
            <h3 class="float-center">
              Emails
            </h3>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- Filters -->
            <b-list-group class="list-group-messages">
              <b-list-group-item
                v-for="filter in emailFilters"
                :key="filter.title + $route.path"
                :to="filter.route"
                :active="isDynamicRouteActive(filter.route)"
                @click="$emit('close-left-sidebar')"
              >
                <feather-icon
                  :icon="filter.icon"
                  size="18"
                  class="mr-75"
                />
                <span class="align-text-bottom line-height-1">{{ filter.title }}</span>
                <!-- <b-badge
                  v-if="emailsMeta[filter.title.toLowerCase()]"
                  pill
                  :variant="resolveFilterBadgeColor(filter.title)"
                  class="float-right"
                >
                  {{ emailsMeta[filter.title.toLowerCase()] }}
                </b-badge> -->
              </b-list-group-item>
            </b-list-group>

            <!-- Labels -->
          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BButton, BListGroup, BListGroupItem, BBadge,
} from 'bootstrap-vue'
import { isDynamicRouteActive } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {

    // BSV
    BButton,
    BListGroup,
    BListGroupItem,
    BBadge,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowEmailComposeModal: {
      type: Boolean,
      required: true,
    },
    // emailsMeta: {
    //   type: Object,
    //   required: true,
    // },
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    const emailFilters = [
      { title: 'Inbox', icon: 'MailIcon', route: { name: 'apps-email' } },
      { title: 'Read', icon: 'FlagIcon', route: { name: 'apps-email-folder', params: { folder: 'read' } } },
      { title: 'See Later', icon: 'CheckSquareIcon', route: { name: 'apps-email-folder', params: { folder: 'seeLater' } } },
      { title: 'Favoriate', icon: 'StarIcon', route: { name: 'apps-email-folder', params: { folder: 'favoriate' } } },
      { title: 'Archived', icon: 'ArchiveIcon', route: { name: 'apps-email-folder', params: { folder: 'archived' } } },
      { title: 'Spam', icon: 'InfoIcon', route: { name: 'apps-email-folder', params: { folder: 'spam' } } },
      { title: 'Trash', icon: 'TrashIcon', route: { name: 'apps-email-folder', params: { folder: 'trash' } } },
    ]
    const resolveFilterBadgeColor = filter => {
      if (filter === 'Draft') return 'light-warning'
      if (filter === 'Spam') return 'light-danger'
      return 'light-primary'
    }

    return {
      // UI
      perfectScrollbarSettings,
      isDynamicRouteActive,
      resolveFilterBadgeColor,

      // Filter & Labels
      emailFilters,
    }
  },
}
</script>

<style>

</style>
